import React from "react";
import { useMsal } from "@azure/msal-react";

import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        color: '#fff'
    }
}));

export const SignOutButton = ({ title }) => {
    const classes = useStyles();
    const { instance } = useMsal();

    const handleLogOut = () => {
        instance.logoutRedirect({
            account: instance.getActiveAccount()
        });
    }
    return (
        <Button
            type="button"
            className={classes.button}
            onClick={handleLogOut}
        >
            {title}
        </Button>
    )
}