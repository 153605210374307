import React from "react";
import { useMsal } from "@azure/msal-react";

import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button: {
        display: "block",
        width: "317px",
        height: "48px",
        marginTop: "2rem",
        textAlign: "center",
        margin: "0 auto",
        backgroundColor: "#086A96",
        fontSize: "1rem",
        borderRadius: "12px",
        '&:hover': {
            backgroundColor: '#07597e',
        }
    }
}));

export const SignInButton = ({ title }) => {
    const classes = useStyles();
    const { instance } = useMsal();

    const handleLogin = () => {
        document.cookie = "msal.interaction.status=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        instance.loginRedirect({}).catch(e => {
            console.error(e);
        });
    }
    return (
        <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleLogin}
        >
            {title}
        </Button>
    )
}