import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Box, Paper, Link } from '@material-ui/core';
import BSLogo from './assests/BSLogo2.png'

export const Footer = ({ view, setView }) => {
    return (
        <Box style={{ width: "100%", height: "2.5rem" }}>
            <Paper elevation={0} style={{ height: "5vh", backgroundColor: view === 'calculator' ? "#F4F5F6" : '#fff', padding: "50px" }} >
                <Grid container>
                    <Grid item><img style={{ width: "150px" }} src={BSLogo} alt='Logo' /></Grid>
                    <Grid item ><Link underline='always' style={{ fontWeight: 700, color: "#021E48", marginLeft: "85px" }} href='' onClick={(e) => {
                        e.preventDefault()
                        setView('use')
                    }}>Indications for Use</Link></Grid>
                    <Grid item ><Link underline='always' style={{ fontWeight: 700, color: "#021E48", marginLeft: "20px" }} href='' onClick={(e) => {
                        e.preventDefault()
                        setView('tool')
                    }}>Tool Info</Link></Grid>
                    <Grid item><Link underline='always' style={{ fontWeight: 700, color: "#021E48", marginTop: "50px", marginLeft: "20px" }} href='' onClick={(e) => {
                        e.preventDefault()
                        setView('help')
                    }}>Help</Link></Grid>
                </Grid>
                <hr style={{ marginTop: "20px" }} />
                <Typography style={{ fontSize: "0.75rem", marginTop: "20px", color: "#565C61" }}>© Copyright 2021 Boston Scientific, All rights reserved.</Typography>
            </Paper>
        </Box>
    );
}

export const MobileFooter = ({ view, setView }) => {
    return (
        <Box style={{ width: "100%", height: "2.5rem" }}>
            <Paper elevation={0} style={{ height: "10vh", backgroundColor: view === 'calculator' ? "#F4F5F6" : '#fff', paddingBottom: "50px", paddingTop: "25px" }} >
                <Grid container justifyContent="center">
                    <Grid item><img style={{ width: "175px" }} src={BSLogo} alt='Logo' /></Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: "15px" }}>
                    <Grid item ><Link underline='always' style={{ fontWeight: 700, color: "#021E48", fontSize: "0.875rem" }} href='' onClick={(e) => {
                        e.preventDefault()
                        setView('use')
                    }}>Indications for Use</Link></Grid>
                    <Grid item ><Link underline='always' style={{ fontWeight: 700, color: "#021E48", marginLeft: "20px", fontSize: "0.875rem" }} href='' onClick={(e) => {
                        e.preventDefault()
                        setView('tool')
                    }}>Tool Info</Link></Grid>
                    <Grid item><Link underline='always' style={{ fontWeight: 700, color: "#021E48", marginLeft: "20px", fontSize: "0.875rem" }} href='' onClick={(e) => {
                        e.preventDefault()
                        setView('help')
                    }}>Help</Link></Grid>
                </Grid>
                <hr style={{ marginTop: "25px", width: "65vw", color: "#D9DBDD" }} />
                <Grid container justifyContent="center">
                    <Typography style={{ fontSize: "0.75rem", color: "#565C61", marginTop: "10px" }}>© Copyright 2021 Boston Scientific, All rights reserved.</Typography>
                </Grid>
            </Paper>
        </Box>
    );
}
