import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper"
import Icon from '@material-ui/core/Icon';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Grid, Button, Box, MenuItem } from '@material-ui/core';

import QuestionMark from './assests/questionmark.png';

import { Footer, MobileFooter } from "./Footer";
import Header from "./Header";

const DesktopTextField = withStyles({

  root: {
    "& .MuiInputBase-root": {
    },
    "& .MuiFormLabel-root": {
      color: "#021E48",
      fontWeight: 700
    },
    "& label.Mui-focused": {
      color: "black",

    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#565C61",
        borderRadius: "12px",
      },
      "&:hover fieldset": {
        borderColor: "#565C61"
      },
      "&.Mui-focused fieldset": {
        border: "3px solid #086A96"
      }
    }
  }
})(TextField);


const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundColor: "#086A96",
    '&:hover': {
      backgroundColor: '#07597e',
    },
    fontSize: "1rem",
    textTransform: "none",
    width: "136px",
    height: "48px",
    borderRadius: "12px",
    color: "white",
  },
  notSelectedButton: {
    backgroundColor: "white",
    '&:hover': {
      backgroundColor: '#grey',
    },
    fontSize: "1rem",
    textTransform: "none",
    width: "136px",
    height: "48px",
    borderRadius: "12px",
    color: "#021E48",
    border: "1px solid",
  },
  submitButton: {
    '&:hover': {
      backgroundColor: '#07597e',
    },
    fontSize: "1rem",
    textTransform: "none",
    width: "280px",
    height: "48px",
    borderRadius: "12px",
    marginTop: "30px",
    color: "white",
  },
  usageTime: {
    width: 280
  }
}));

function calculate_eui(eui, hours) {
  var max = 10.00;
  // formula for getting the longevity in years
  var E = hours === 12 ? (0.5 * (eui + 2.77)) : eui;
  var K = 0.887 * 6500 / 0.0185 / E / 24 / 365;
  var longevity = (K * Math.exp(-0.017 * K)).toFixed(2);
  console.log(longevity)
  if (longevity > max) {
    return "10+ years"
  } else {
    // time left in hours
    var time_left = parseInt(longevity * 365 * 24);

    if (time_left === 0) {
      let time_output = "Please choose the device type. ";
      return time_output;
    }

    // turning hours into years, months, days, hours
    var years_left = 0, months_left = 0;
    let time_output = ""
    if (time_left > 8760) {
      years_left = parseInt(time_left / 8760);
      time_left = time_left - years_left * 8760;
      time_output = time_output + years_left + " year";
      time_output = years_left === 1 ? time_output + " and " : time_output + "s and ";
    }
    if (time_left > 732) {
      months_left = parseInt(time_left / 732);
      time_left = parseInt(time_left - months_left * 732);
      time_output = time_output + months_left + " month";
      time_output = months_left === 1 ? time_output + ". " : time_output + "s. ";
    }
    else {
      time_output = time_output.substring(0, time_output.length - 5) + ".";
    }
    return time_output;
  }
}



const HoverableToolTip = ({ handleMouseOver, handleMouseOut }) => {
  return (
    <div>
      <Grid container style={{ marginTop: "6px" }}>
        <Typography style={{ color: "#565C61", fontSize: "0.75rem" }}>Learn how to view the EUI</Typography>
        <img style={{ width: "15px", height: "15px", marginTop: "1px", marginLeft: "5px" }} src={QuestionMark} alt="?" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} />
      </Grid>
    </div>
  );
};

const HoverText = () => {
  return (
    <div>
      <Paper elevation={1} style={{ width: "430px", height: "176px", borderRadius: "12px", padding: "12px", position: "absolute", marginLeft: "30px" }}>
        <Typography style={{ fontWeight: "700", fontSize: "1.2rem" }}>
          View the EUI While Connected to Patient
        </Typography>
        <Typography style={{ margin: "0px", fontSize: "0.875rem", marginTop: "6px", color: '#565C61' }}>To view the EUI, go to the Program Drop-Down Menu in the CP.</Typography>
        <ol style={{ padding: "12px", fontSize: "0.875rem", margin: "0px", color: "#565C61" }}>
          <li>Select the desired Program from the Program Carousel</li>
          <li style={{ marginTop: "5px" }}>Select the drop-down menu icon next to the Program Name and select “Battery Estimator”.</li>
        </ol>
        <Typography style={{ margin: "0px", fontSize: "0.625rem", marginTop: "6px", color: '#565C61', fontStyle: 'italic' }}>Note: The CP must be connected to the patient whether trialing on Alpha ETS or during initial programming on Alpha Prime to get EUI.</Typography>
      </Paper>
    </div>
  );
};

const MobileHoverText = (props) => {
  return (
    <div>
      <Paper elevation={5} style={{ width: "66vw", maxWidth: "250px", minHeight: "300px", borderRadius: "12px", padding: "12px", zIndex: "999", position: "absolute" }}>
        <Typography style={{ fontWeight: "700", fontSize: "1.2rem" }}>
          View the EUI While Connected to Patient
        </Typography>
        <Typography style={{ margin: "0px", fontSize: "0.875rem", marginTop: "6px", color: '#565C61' }}>To view the EUI, go to the Program Drop-Down Menu in the CP.</Typography>
        <ol style={{ padding: "12px", fontSize: "0.875rem", margin: "0px", color: "#565C61" }}>
          <li>Select the desired Program from the Program Carousel</li>
          <li style={{ marginTop: "10px" }}>Select the drop-down menu icon next to the Program Name and select “Battery Estimator”.</li>
        </ol>
        <Typography style={{ margin: "0px", fontSize: "0.625rem", marginTop: "6px", color: '#565C61', fontStyle: 'italic' }}>Note: The CP must be connected to the patient whether trialing on Alpha ETS or during initial programming on Alpha Prime to get EUI.</Typography>
        <Box style={{ display: 'flex', marginTop: 6 }}>
          <Box style={{ flex: 1 }}></Box>
          <Button variant='contained' style={{ backgroundColor: "#086A96", color: '#fff', textTransform: 'none' }}>Got it</Button>
        </Box>

      </Paper>
    </div>
  );
};


// const DisplayMobile = () => {

//   const [eui, setEUI] = React.useState('');

//   const handleChange = (event) => {
//       setEUI(event.target.value);
//     };

//   return (
//       <React.Fragment>
//           <AppBar position="static" style = {{backgroundColor: "#021E48", width: '100%'}}>
//               <Toolbar style = {{ height:"15vh", alignContent: "center"}}>
//               <img src={BSLogo} style = {{width: "20%", display: "block",marginLeft: "auto",marginRight: "auto"}} alt="Boston Scientific Logo" />
//               </Toolbar>
//           </AppBar>
//           <Typography align = "center" style = {{fontWeight: "700", fontSize: "3rem",marginTop: "8%"}}>Alpha PC EUI Conversion Tool</Typography>
//           <Typography align = "center" style = {{fontWeight: "400", fontSize: "2rem",marginTop: "2%", color: "#565C61"}}>Alpha Prime Battery Longevity</Typography>
//           <Paper elevation = {0} style = {{backgroundColor: '#F4F5F6', height: "100vh", marginLeft: "24px", marginRight: "24px", borderRadius: "12px", marginTop: "24px", paddingLeft: '40px', paddingRight: "40px"}}> 
//           <MobileTextField
//               style = {{width: "100%", marginTop: "40px", borderRadius: "12px"}}
//               variant="outlined"
//               label="Alpha PC EUI"
//               name = "eui"
//               onChange = {handleChange}
//               value = {eui}
//               >
//           </MobileTextField>
//           </Paper> 
//     </React.Fragment>
//   )};

const isEuiValid = (eui) => {
  const pre = Number(eui)
  if (!isNaN(pre) && pre >= 1 && pre <= 100) {
    if (eui.split('.')[1]?.length > 1) {
      return false
    }
    return true
  } else {
    return false
  }
}

const Calculator = ({
  calcEUI,
  touched,
  handleChange,
  eui,
  handleMouseOver,
  handleMouseOut,
  isHovering,
  mobileView,
  hours,
  setHours,
  GetEUI
}) => {
  const classes = useStyles();

  return (
    <div style={{ marginBottom: calcEUI !== '' ? "15vh " : "35vh" }}>
      <Typography align="center" style={{ fontSize: "1.5rem", fontWeight: "700", marginTop: "56px" }}>Alpha PC EUI Conversion Tool</Typography>
      <Typography align="center" style={{ fontSize: "1rem", fontColor: "#565C61", marginTop: "3px", color: "#565C61" }}>Alpha Prime Battery Longevity</Typography>
      <Grid container justifyContent="center">
        <Paper elevation={0} style={{ backgroundColor: "#F4F5F6", height: "350px", width: "87vw", marginTop: "32px", borderRadius: "12px", maxWidth: "555px", }} >
          <Grid container justifyContent="center" style={{ marginTop: "40px" }}>
            <Grid item>
              <DesktopTextField
                error={touched && !isEuiValid(eui)}
                //type="number"
                style={{ width: "280px" }}
                variant="outlined"
                label="Alpha PC EUI"
                name="euiInput"
                onChange={handleChange}
                value={eui}
                helperText={touched && !isEuiValid(eui) ? "Please input a valid value" : ""}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  placeholder: 'Click to Enter  EUI'
                }}
              />
              <HoverableToolTip
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut} />
            </Grid>
            {(isHovering && !mobileView) ? <HoverText /> : <></>}
          </Grid>
          {(isHovering && mobileView) ? <Box style={{ marginLeft: "calc(33vw / 2)" }}><MobileHoverText style={{ marginLeft: "10px" }} /> </Box> : <></>}
          <div style={{ width: "100%" }}>
            <Box display="flex" justifyContent="center" m={1} p={1}>
              <DesktopTextField
                className={classes.usageTime}
                variant="outlined"
                label="Usage Time"
                select
                onChange={(evt) => { setHours(Number(evt.target.value)) }}
                name="usageTime"
                value={hours}
              //helperText={locationError ? "This field is required" : ''}
              >
                <MenuItem value={24}>24-hour</MenuItem>
                <MenuItem value={12}>12-hour</MenuItem>
              </DesktopTextField>
            </Box>
          </div>
          <Grid container justifyContent="center" >
            <Button disabled={!isEuiValid(eui)} className={classes.submitButton} style={{ backgroundColor: isEuiValid(eui) ? '#086A96' : '#E9EBEC' }} onClick={GetEUI}><Typography style={{ fontWeight: 700, }}>Calculate</Typography></Button>
          </Grid>
        </Paper>
      </Grid>
      <Grid container justifyContent="center">
        {calcEUI !== '' && <Paper elevation={0} style={{ backgroundColor: "#F4F5F6", height: "126px", width: "555px", marginTop: "32px", borderRadius: "12px", paddingTop: '32px' }} >
          <Typography align='center'>This battery will last approximately for</Typography>
          <Typography align='center' style={{ marginTop: "6px", fontWeight: 700, fontSize: "2rem", color: "#086A96" }}>{calcEUI}</Typography>
        </Paper>}
      </Grid>
    </div>
  )
}

const EUICalculator = () => {
  const [eui, setEUI] = React.useState('');
  const [calcEUI, setCalcEUI] = React.useState('');
  const [isHovering, setIsHovering] = useState(false);
  const [touched, setTouched] = useState(false);
  const [state, setState] = useState({ mobileView: false });
  const [hours, setHours] = useState(24);

  const { mobileView } = state;

  const handleChange = (event) => {
    setTouched(event.target.value === '' ? false : true);
    setEUI(event.target.value);
  };

  let timer
  const handleMouseOver = () => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      setIsHovering(true);
    }, 500)
  };

  const handleMouseOut = () => {
    if (timer) clearTimeout(timer)
    setIsHovering(false);
  };

  const GetEUI = () => {
    if (eui === '') {
      setCalcEUI('')
    } else {
      setCalcEUI(calculate_eui(parseFloat(eui), parseInt(hours)));
    }
  }
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1200
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const [view, setView] = useState('calculator')

  const Info = ({ view, setView, mobileView }) => {
    const getMsg = (view) => {
      switch (view) {
        case 'tool':
          return {
            title: 'Tool Info',
            content: ['The approximate longevity of an Alpha Prime IPG can be estimated at the time of initial programming by entering the EUI into the tool below. These estimates will not reflect adjustments to stimulation parameters or changes in impedance. Battery life is dependent on stimulation settings and conditions. Note: This tool is not valid if reference at any time other than the initial programming after implant. This tool is based on values calculated from Technical Report 92458060 and rounded up to 2 decimal places.']
          }
        case 'use':
          return {
            title: 'Alpha PC EUI Conversion Tool',
            content: ['Indications for Use. The Boston Scientific Spinal Cord Stimulator Systems are indicated as an aid in the management of chronic intractable pain of the trunk and/or limbs including unilateral or bilateral pain associated with the following: failed back surgery syndrome, Complex Regional Pain Syndrome (CRPS) Types I and II, intractable low back pain and leg pain. Associated conditions and etiologies may be: radicular pain syndrome, radiculopathies resulting in pain secondary to failed back syndrome or herniated disc, epidural fibrosis, degenerative disc disease (herniated disc pain refractory to conservative and surgical interventions), arachnoiditis, multiple back surgeries. Contraindications, warnings, precautions, side effects. The SCS Systems are contraindicated for patients who: are unable to operate SCS System, have failed trial stimulation by failing to receive effective pain relief, are pool surgical risks, or are pregnant. Refer to the instructions for Use provided with the SCS System or Pain.com for potential adverse effects, warnings, and precautions prior to using this product. Caution: U.S. Federal law restricts this device to sale by or on the order of a physician.',
              'Boston Scientific Confidential – For Internal Use Only. Do Not Copy, Display or Distribute Externally.',
              'NM-1085109-AA © 2021 Boston Scientific Corporation or its affiliates.',
              'All rights reserved.​']
          }
        case 'help':
          return {
            title: 'Having Troubles?',
            content: ['Please email <a href = "mailto: support@alphaeui.com">support@alphaeui.com</a> and someone will be in contact with you. Thank you!']
          }
        default:
          throw new Error('Unexpected view')
      }
    }

    const { title, content } = getMsg(view);
    return (
      <div style={{ flex: 1, backgroundColor: "#F4F5F6" }}>
        <div style={{ width: mobileView ? '90%' : '50%', height: mobileView ? 'auto' : '64%', margin: '98px auto' }}>
          <Button onClick={() => setView('calculator')} startIcon={<Icon>arrow_back_ios</Icon>} style={{
            color: '#086A96',
            marginBottom: '25px'
          }}>Back</Button>
          <Paper style={{ height: '100%', padding: '24px 32px', borderRadius: 12 }}>
            <Typography style={{
              fontWeight: 700,
              fontSize: '32px'
            }}>{title}</Typography>
            {content.map((p, i) => (
              <Typography key={i} style={{
                marginTop: '16px'
              }} dangerouslySetInnerHTML={{ "__html": p }} />
            ))}
          </Paper>
        </div>
      </div>
    )
  }

  const generateBody = (view) => {
    return view === 'calculator' ? <Calculator calcEUI={calcEUI}
      touched={touched}
      handleChange={handleChange}
      eui={eui}
      handleMouseOver={handleMouseOver}
      handleMouseOut={handleMouseOut}
      isHovering={isHovering}
      mobileView={mobileView}
      hours={hours}
      setHours={setHours}
      GetEUI={GetEUI}
    /> : <Info view={view} setView={setView} mobileView={mobileView} />
  }

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Header />
      {generateBody(view)}
      {!mobileView ? <Footer view={view} setView={setView} /> : <MobileFooter view={view} setView={setView} />}
    </div>
  );
}

export default EUICalculator