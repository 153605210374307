import React from "react";
import EUICalculator from './EUICalculator';
import Login from "./Login";
import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ScreenProvider } from "./screenContext";

const App = ({ pca }) => {
  return (
    <ScreenProvider>
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
          <EUICalculator />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </ScreenProvider>
  );
}
export default App;
