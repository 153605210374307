import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import BSLogo from './assests/BSLogo.png';
import { SignOutButton } from './SignOutButton';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  title: {
    flexGrow: 1,
    fontSize: "1rem"
  },
  logo: {
    width: "115px",
    marginBottom: "6px"
  },
  divider: {
    background: "#FFFFFF",
    opacity: "24%"
  }
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "#021E48" }}>
        <Toolbar style={{ minHeight: "88px", paddingLeft: "40px", paddingRight: "40px" }}>
          <img src={BSLogo} className={classes.logo} alt="Boston Scientific Logo" />

          <Typography variant="h6" className={classes.title}>

          </Typography>
          <SignOutButton title='Sign out' />
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Header