import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BSLogo from './assests/BSLogoBlue.png';
import LoginImg from './assests/BSC Login Image.png';
import { SignInButton } from './SignInButton';
import { useScreenContext } from './screenContext';

const useStyles = makeStyles((theme) => ({
    welcome: {
        fontSize: "28px",
        textAlign: "center",
    },
    logo: {
        width: '50%'
    },
    icon: {
        width: "40%",
        paddingTop: theme.spacing(4),

    },
    splitscreen: {
        display: 'flex',
        flexDirection: 'row',
        position: "relative",
        height: "100vh",
        overflow: 'hidden'
    },
    leftpane: {
        width: '50%',
        padding: '1.5rem 1.5rem 0',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        alignItems: 'center'
    },
    rightpane: {
        width: '50%',
        backgroundColor: "#F4F5F6",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    passport: {
        fontSize: "28px",
        fontWeight: "700",
        textAlign: "center"
    },
    root: {
        padding: '1.5rem',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        alignItems: 'center'
    },
    logoMobile: {
        marginTop: '2.5rem',
        width: '80%'
    }
}));

const Login = () => {
    const classes = useStyles();
    const { desktop } = useScreenContext();

    return desktop ? (
        <div className={classes.splitscreen}>
            <div className={classes.leftpane}>
                <Typography className={classes.welcome}>Welcome to</Typography>
                <img src={BSLogo} className={classes.logo} alt="Boston Scientific Logo" />
                <Typography className={classes.passport}>Alpha Prime EUI Conversion Tool</Typography>
                <img src={LoginImg} style={{ width: '69%' }} alt="Login" />
            </div>
            <div className={classes.rightpane}>
                <Typography style={{ fontSize: "1.5rem", fontWeight: "700", textAlign: "center" }}>Sign In</Typography>
                <SignInButton title='Sign in with BSCI' />
            </div>
        </div>
    ) : (
        <div className={classes.root}>
            <Typography className={classes.welcome}>Welcome to</Typography>
            <img src={BSLogo} className={classes.logoMobile} alt="Boston Scientific Logo" />
            <Typography className={classes.passport}>Alpha Prime EUI Conversion Tool</Typography>
            <img src={LoginImg} style={{ width: '80%', marginBottom: '1.2rem' }} alt="Login" />
            <Typography style={{ fontSize: "1.5rem", fontWeight: "700", textAlign: "center" }}>Sign In</Typography>
            <SignInButton title='Sign in with BSCI' />

        </div>
    )
}

export default Login;