import { LogLevel } from "@azure/msal-browser";

const origin = window.location.origin

export const msalConfig = {
    auth: {
        clientId: "799930f1-1c30-4ac8-a5d1-8d3e632852ba",
        authority: "https://login.microsoftonline.com/b5b8b483-5597-4ae7-8e27-fcc464a3b584",
        redirectUri: origin,
        postLogoutRedirectUri: origin
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    state: 'bsc'
};