import React, { createContext, useContext } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ScreenContext = createContext();

const ScreenProvider = ({ children }) => {
    const desktop = useMediaQuery('(min-width:768px)');
    // useEffect(() => {
    //     console.log(desktop)
    // }, [desktop])

    return (
        <ScreenContext.Provider value={{ desktop: desktop }}>
            {children}
        </ScreenContext.Provider >
    );
}

const useScreenContext = () => {
    const context = useContext(ScreenContext);
    if (!context) throw new Error('useScreenContext must be used within a ScreenProvider')
    return context;
}

export { ScreenProvider, useScreenContext };
